import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import App from './App'
import 'App.css'

if (process.browser) {
  // eslint-disable-next-line no-console
  console.log(
    `
      ███████╗ ██████╗ ██████╗ ██╗██████╗
      ██╔════╝██╔════╝ ██╔══██╗██║██╔══██╗
      █████╗  ██║  ███╗██████╔╝██║██║  ██║
      ██╔══╝  ██║   ██║██╔══██╗██║██║  ██║
      ███████╗╚██████╔╝██║  ██║██║██████╔╝
      ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═╝╚═════╝

    ${window.location.origin}
    `
  )
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register()
